<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Logout",
  async created() {
    await this.logout();
    this.$router.push("/login");
  },
  methods: {
    ...mapActions("users", ["logout"])
  }
};
</script>

<style>
</style>